import React, { useState } from "react";

function App() {
  const [count, setCount] = useState(0);
	return (
		<div id="MainContainer" className="container my-5" onClick={() => setCount(count + 1)}>
			<div className="card text-center my-5">
				<div className="card-body">
					<h1>Sujud Counter</h1>
				</div>
			</div>

			<div className="my-5 text-center">
				<h2 id="CounterLabel">{count}</h2>
			</div>

			<div className="my-0 text-center">
				<button className="btn btn-success btn-lg mx-3 fs-1 fullWidth-btn" onClick={() => setCount(count + 1)}>Add</button>
			</div>
		</div>
	);
}

export default App;
